

form {
  label {
    text-transform: uppercase;
    font-weight: 700;
  }
  &.bionic-countdown {
    max-width: 380px;
    margin:0 auto;
  }
}


.button {
  text-transform: uppercase;
  font-weight: 700;
  &.primary {
    color:white;
    &:hover {
      color:white;
    }
  }
}



$padding: 2rem;
.padding-top {
  padding-top: $padding;
}
.padding-bottom {
  padding-bottom: $padding;
}
